import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { UserService, Crypto } from '../../../_services';

@Component({
  selector: 'app-calligraphy-popup',
  templateUrl: './calligraphy-popup.component.html',
  styleUrls: ['./calligraphy-popup.component.scss']
})
export class CalligraphyPopupComponent implements OnInit {

  userRecordsServerSideResponse: any;

  userRecordsServerSideResponseData: any;

  havetoshow: any;

  constructor(
    public dialogRef: MatDialogRef<CalligraphyPopupComponent>,
    private _userService: UserService,
    private cryptoJS: Crypto,
    @Inject(MAT_DIALOG_DATA) public data: any) { /*console.log(data);*/
     }

  ngOnInit() {

    this.newUsersRecords();
    let k = JSON.parse(localStorage.getItem('userData'));
    this.havetoshow = k.calligraphy == 'n' ? false : true;
  }

  onNoClick(): void {
    localStorage.setItem('calligraphy', 'false');
    this.dialogRef.close();
  }
  
  changetoshow() {
    let pp = this.havetoshow ? 'y' : 'n';
    let user = JSON.parse(localStorage.getItem('userData'));
    let req = {
      uid: user.id,
      calligraphy: pp
    }

    try {
      this._userService.saveCalligraphyForuser(req)
        .pipe()
        .subscribe((res) => {
          user.calligraphy = pp;
          localStorage.setItem(JSON.stringify(user), 'userData');

        }, err => { });
    } catch (err) { }

  }
  
  newUsersRecords() {
    this._userService.getCalligraphy().pipe()
      .subscribe(res => {
        this.userRecordsServerSideResponse = res;
        if (this.userRecordsServerSideResponse.success === true) {
            this.userRecordsServerSideResponseData = this.cryptoJS.decryptData(this.userRecordsServerSideResponse.data);
            this.userRecordsServerSideResponseData = this.userRecordsServerSideResponseData[0];
        }
      });
  }


}
