import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil, combineAll } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { navigation } from 'app/navigation/navigation';
import { locale as navigationEnglish } from 'app/navigation/i18n/en';
import { locale as navigationTurkish } from 'app/navigation/i18n/tr';
import { UserService, pushNotification } from './_services';
import { first } from 'rxjs/operators';

@Component({
    selector: 'app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    fuseConfig: any;
    navigation: any;

    notificationResponse: any;
    notificationData = [];

    companyClauseDataResponse: any;
    companyClauseDataResponseData = [];

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {DOCUMENT} document
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {FuseSplashScreenService} _fuseSplashScreenService
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     * @param {Platform} _platform
     * @param {TranslateService} _translateService
     */
    constructor(
        @Inject(DOCUMENT) private document: any,
        private _userService: UserService,
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _platform: Platform,
        private _pushNotification: pushNotification,
    ) {
        // Get default navigation
        this.navigation = navigation;

        // Register the navigation to the service
        this._fuseNavigationService.register('main', this.navigation);

        // Set the main navigation as our current navigation
        this._fuseNavigationService.setCurrentNavigation('main');

        // Add languages
        this._translateService.addLangs(['en', 'tr']);

        // Set the default language
        this._translateService.setDefaultLang('en');

        // Set the navigation translations
        this._fuseTranslationLoaderService.loadTranslations(navigationEnglish, navigationTurkish);

        // Use a language
        this._translateService.use('en');

        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix Start
         * ----------------------------------------------------------------------------------------------------
         */

        /**
         * If you are using a language other than the default one, i.e. Turkish in this case,
         * you may encounter an issue where some of the components are not actually being
         * translated when your app first initialized.
         *
         * This is related to ngxTranslate module and below there is a temporary fix while we
         * are moving the multi language implementation over to the Angular's core language
         * service.
         **/

        // Set the default language to 'en' and then back to 'tr'.
        // '.use' cannot be used here as ngxTranslate won't switch to a language that's already
        // been selected and there is no way to force it, so we overcome the issue by switching
        // the default language back and forth.
        /**
         setTimeout(() => {
            this._translateService.setDefaultLang('en');
            this._translateService.setDefaultLang('tr');
         });
         */

        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix End
         * ----------------------------------------------------------------------------------------------------
         */

        // Add is-mobile class to the body if the platform is mobile
        if (this._platform.ANDROID || this._platform.IOS) {
            this.document.body.classList.add('is-mobile');
        }

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

        this._pushNotification.requestPermission()
        this._pushNotification.receiveMessage()

        // Subscribe to config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {

                this.fuseConfig = config;

                // Boxed
                if (this.fuseConfig.layout.width === 'boxed') {
                    this.document.body.classList.add('boxed');
                }
                else {
                    this.document.body.classList.remove('boxed');
                }

                // Color theme - Use normal for loop for IE11 compatibility
                for (let i = 0; i < this.document.body.classList.length; i++) {
                    const className = this.document.body.classList[i];

                    if (className.startsWith('theme-')) {
                        this.document.body.classList.remove(className);
                    }
                }

                this.document.body.classList.add(this.fuseConfig.colorTheme);
            });

        var companyName = localStorage.getItem('companyName');

        if (companyName == '') {
            this.getCompanyInfo();
        }

        if (companyName == null) {
            this.getCompanyInfo();
        }

        if (localStorage.getItem('userData')) {
            let data = JSON.parse(localStorage.getItem('userData'));
            let req = {
                id: data.id,
                notificationToken: this._pushNotification.notificationToken
            }

            this.pushNotification(req);
        }
    }

    public pushNotification(req) {
        if (req.id) {
            this._userService.pushNotificationTokenUpdate(req)
                .subscribe((res: any) => {
                    if (res.success) {
                        // console.log("success", res);
                    }
                    else {
                        let data = JSON.parse(localStorage.getItem('userData'));
                        this._pushNotification.requestPermission();
                        this.pushNotification({ id: data.id, notificationToken: this._pushNotification.notificationToken })
                    }
                });
        }
    }

    getCompanyInfo() {
        var companyCondition = {};
        companyCondition["id"] = JSON.parse(localStorage.getItem('companyId'));

        try {
            this._userService.fetchCompanyData(companyCondition).pipe(first()).subscribe((res) => {
                this.companyClauseDataResponse = res;
                if (this.companyClauseDataResponse.success === true) {
                    this.companyClauseDataResponseData = this.companyClauseDataResponse.data;
                    var companyInfo = this.companyClauseDataResponseData[0];
                    if (companyInfo != '' && companyInfo != null && companyInfo != undefined) {
                        localStorage.setItem('companyName', companyInfo.companyName);
                    }
                }
            })
        } catch (err) { }
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }



    checkNotification(): void {
        var filterCondition = {};
        filterCondition["toUserId"] = localStorage.getItem('userId');
        filterCondition["is_read"] = 'N';
        filterCondition["userRoleId"] = localStorage.getItem('userRoleId');
        try {
            this._userService.notificationRecords(filterCondition).pipe(first()).subscribe((res) => {
                this.notificationResponse = res;
                if (this.notificationResponse.success === true) {
                    console.log("notificationResponse", this.notificationResponse);
                }
            });
        }
        catch (err) { }
    }
}
