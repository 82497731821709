import { FuseNavigation } from '@fuse/types';


var childrenArray = [];
if (JSON.parse(localStorage.getItem('userRoleId')) != '7') {
    var childrenArrayInfo = {};
    childrenArrayInfo["id"] = 'dashboard';
    childrenArrayInfo["title"] = 'Dashboard';
    childrenArrayInfo["translate"] = 'NAV.DASHBOARDS';
    childrenArrayInfo["type"] = 'item';
    childrenArrayInfo["icon"] = 'network_check';
    childrenArrayInfo["url"] = '/apps/dashboards/analytics';
    childrenArray.push(childrenArrayInfo);
}

if (JSON.parse(localStorage.getItem('userRoleId')) != '7') {

    var childrenArrayInfo = {};
    childrenArrayInfo["id"] = 'Draw C/P';
    childrenArrayInfo["title"] = 'Draw C/P';
    childrenArrayInfo["translate"] = 'NAV.CHARTERERMANAGEMENT';
    childrenArrayInfo["type"] = 'item';
    childrenArrayInfo["icon"] = 'brush';
    childrenArrayInfo["url"] = '/apps/draw-management';

    childrenArray.push(childrenArrayInfo);

    var childrenArrayInfo = {};
    childrenArrayInfo["id"] = 'Trading Platform';
    childrenArrayInfo["title"] = 'Trading';
    childrenArrayInfo["translate"] = 'NAV.CHARTERERMANAGEMENT';
    childrenArrayInfo["type"] = 'item';
    childrenArrayInfo["icon"] = 'merge_type';
    childrenArrayInfo["url"] = '/apps/trading-platform-management';

    childrenArray.push(childrenArrayInfo);

    /* not used more */
    /* var childrenArrayInfo = {};
    childrenArrayInfo["id"] = 'Invited Users';
    childrenArrayInfo["title"] = 'Invited Users';
    childrenArrayInfo["translate"] = 'NAV.CHARTERERMANAGEMENT';
    childrenArrayInfo["type"] = 'item';
    childrenArrayInfo["icon"] = 'email';
    childrenArrayInfo["url"] = '/apps/invite';
    
    childrenArray.push(childrenArrayInfo); */
    /* not used more */

    if (JSON.parse(localStorage.getItem('userRoleId')) == '5') {
        var childrenArrayInfo = {};
        childrenArrayInfo["id"] = 'Ownership Transferred';
        childrenArrayInfo["title"] = 'Ownership Transfer';
        childrenArrayInfo["translate"] = 'NAV.CHARTERERMANAGEMENT';
        childrenArrayInfo["type"] = 'item';
        childrenArrayInfo["icon"] = 'transfer_within_a_station';
        childrenArrayInfo["url"] = '/apps/ownership';
        childrenArray.push(childrenArrayInfo);

        var childrenArrayInfo = {};
        childrenArrayInfo["id"] = 'Share Trade';
        childrenArrayInfo["title"] = 'Share Draw CP or Trade';
        childrenArrayInfo["translate"] = 'NAV.CHARTERERMANAGEMENT';
        childrenArrayInfo["type"] = 'item';
        childrenArrayInfo["icon"] = 'share';
        childrenArrayInfo["url"] = '/apps/shareTrade';
        childrenArray.push(childrenArrayInfo);
    }

    var childrenArrayInfo = {};
    childrenArrayInfo["id"] = 'Messaging Board';
    childrenArrayInfo["title"] = 'Messaging';
    childrenArrayInfo["type"] = 'item';
    childrenArrayInfo["icon"] = 'message';
    childrenArrayInfo["url"] = '/apps/messaging-board';

    childrenArray.push(childrenArrayInfo);

    // var childrenArrayInfo = {};
    // childrenArrayInfo["id"] = 'Chat ';
    // childrenArrayInfo["title"] = 'Chat Board';
    // childrenArrayInfo["type"] = 'item';
    // childrenArrayInfo["icon"] = 'chat_bubble';
    // childrenArrayInfo["url"] = '/apps/chat-management';
    // childrenArray.push(childrenArrayInfo);

    // var childrenArrayInfo = {};
    // childrenArrayInfo["id"] = 'Platform Admin ';
    // childrenArrayInfo["title"] = 'Support';
    // childrenArrayInfo["type"] = 'collapsable';
    // childrenArrayInfo["icon"] = 'speaker_notes';
    // childrenArrayInfo["children"] = [

    //     {
    //         id: 'contact',
    //         title: 'User Directory',
    //         type: 'item',
    //         icon: 'speaker_notes',
    //         url: '/apps/contact',
    //         exactMatch: true
    //     },
    //     // {
    //     //     id: 'Notification',
    //     //     title: 'Notification',
    //     //     type: 'item',
    //     //     icon: 'notifications_active',
    //     //     url: '/apps/notification',
    //     //     exactMatch: true
    //     // },
    //     {
    //         id: 'Report Platform Bugs & Suggestions',
    //         title: 'Report Platform Bugs & Suggestions',
    //         type: 'item',
    //         icon: 'speaker_notes',
    //         url: '/apps/platform-admin',
    //         exactMatch: true
    //     },
    //     {
    //         id: 'Frequently Asked Questions',
    //         title: 'Frequently Asked Questions',
    //         type: 'item',
    //         icon: 'notes',
    //         url: '/apps/faq-management',
    //         exactMatch: true
    //     },
    //     {
    //         id: 'Platform Terms & Conditions',
    //         title: 'Platform Terms & Conditions',
    //         type: 'item',
    //         icon: 'speaker_notes',
    //         url: '/apps/term-management',
    //         exactMatch: true
    //     }



    // ];
    // childrenArray.push(childrenArrayInfo);


    // var childrenArrayInfo = {};
    // childrenArrayInfo["id"] = 'Report Platform Bugs & Suggestions';
    // childrenArrayInfo["title"] = 'Report Platform Bugs & Suggestions';
    // childrenArrayInfo["type"] = 'item';
    // childrenArrayInfo["icon"] = 'speaker_notes';
    // childrenArrayInfo["url"] = '/apps/platform-admin';
    // childrenArray.push(childrenArrayInfo);

    // var childrenArrayInfo = {};
    // childrenArrayInfo["id"] = 'Frequently Asked Questions';
    // childrenArrayInfo["title"] = 'Frequently Asked Questions';
    // childrenArrayInfo["type"] = 'item';
    // childrenArrayInfo["icon"] = 'notes';
    // childrenArrayInfo["url"] = '/apps/faq-management';
    // childrenArray.push(childrenArrayInfo);



    // var childrenArrayInfo = {};
    // childrenArrayInfo["id"] = 'Platform Terms & Conditions';
    // childrenArrayInfo["title"] = 'Platform Terms & Conditions';
    // childrenArrayInfo["type"] = 'item';
    // childrenArrayInfo["icon"] = 'speaker_notes';
    // childrenArrayInfo["url"] = '/apps/term-management';
    // childrenArray.push(childrenArrayInfo);


    // var childrenArrayInfo = {};
    // childrenArrayInfo["id"] = 'contact';
    // childrenArrayInfo["title"] = 'User Directory';
    // childrenArrayInfo["type"] = 'item';
    // childrenArrayInfo["icon"] = 'notes';
    // childrenArrayInfo["url"] = '/apps/contact';
    // childrenArray.push(childrenArrayInfo);



    // var childrenArrayInfo = {};
    // childrenArrayInfo["id"] = 'Notification';
    // childrenArrayInfo["title"] = 'Notification';
    // childrenArrayInfo["type"] = 'item';
    // childrenArrayInfo["icon"] = 'notifications_active';
    // childrenArrayInfo["url"] = '/apps/notification';

    // childrenArray.push(childrenArrayInfo);
}

if (JSON.parse(localStorage.getItem('userRoleId')) == '7') {
    var childrenArrayInfo = {};
    childrenArrayInfo["id"] = 'Draw C/P';
    childrenArrayInfo["title"] = 'Draw C/P';
    childrenArrayInfo["translate"] = 'NAV.CHARTERERMANAGEMENT';
    childrenArrayInfo["type"] = 'item';
    childrenArrayInfo["icon"] = 'brush';
    childrenArrayInfo["url"] = '/apps/draw-management';

    childrenArray.push(childrenArrayInfo);

    // var childrenArrayInfo = {};
    // childrenArrayInfo["id"] = 'Trading Platform';
    // childrenArrayInfo["title"] = 'Trading';
    // childrenArrayInfo["translate"] = 'NAV.CHARTERERMANAGEMENT';
    // childrenArrayInfo["type"] = 'item';
    // childrenArrayInfo["icon"] = 'merge_type';
    // childrenArrayInfo["url"] = '/apps/trading-platform-management';

    // childrenArray.push(childrenArrayInfo);
}

if (JSON.parse(localStorage.getItem('userRoleId')) == '5') {
    var childrenArrayInfo = {};
    childrenArrayInfo["id"] = 'user';
    childrenArrayInfo["title"] = 'Users Administrator';
    childrenArrayInfo["translate"] = 'NAV.USERMANAGEMENT';
    childrenArrayInfo["type"] = 'item';
    childrenArrayInfo["icon"] = 'person_add';
    childrenArrayInfo["url"] = '/apps/user-administrator';

    childrenArray.push(childrenArrayInfo);

    var childrenArrayInfo = {};
    childrenArrayInfo["id"] = 'contact';
    childrenArrayInfo["title"] = 'User Directory';
    childrenArrayInfo["translate"] = 'NAV.USERMANAGEMENT';
    childrenArrayInfo["type"] = 'item';
    childrenArrayInfo["icon"] = 'speaker_notes';
    childrenArrayInfo["url"] = '/apps/contact';

    childrenArray.push(childrenArrayInfo);

    // if (JSON.parse(localStorage.getItem('userData')).companytype == 3 || JSON.parse(localStorage.getItem('userData')).companytype == 6) {
    //     var childrenArrayInfo = {};
    //     childrenArrayInfo["id"] = 'vessel-management';
    //     childrenArrayInfo["title"] = 'Vessel Management';
    //     childrenArrayInfo["translate"] = 'NAV.VESSEL';
    //     childrenArrayInfo["type"] = 'item';
    //     childrenArrayInfo["icon"] = 'directions_boat';
    //     childrenArrayInfo["url"] = '/apps/vessel-management';

    //     childrenArray.push(childrenArrayInfo);
    // }

    // var childrenArrayInfo = {};

    // childrenArrayInfo["id"] = 'currency_managment';
    // childrenArrayInfo["title"] = 'Currency Management';
    // childrenArrayInfo["type"] = 'item';
    // childrenArrayInfo["icon"] = 'money';
    // childrenArrayInfo["url"] = '/apps/currency-managment';
    // childrenArray.push(childrenArrayInfo);

    if (JSON.parse(localStorage.getItem('userData')).companytype == 4) {
        var childrenArrayInfo = {};
        childrenArrayInfo["id"] = 'Management';
        childrenArrayInfo["title"] = 'Management';
        childrenArrayInfo["type"] = 'collapsable';
        childrenArrayInfo["icon"] = 'menu';
        childrenArrayInfo["children"] = [
            {
                id: 'currency_managment',
                title: 'Currency Management',
                type: 'item',
                icon: 'money',
                url: '/apps/currency-managment',
                exactMatch: true
            },
        ];
        childrenArray.push(childrenArrayInfo);
    }

    if (JSON.parse(localStorage.getItem('userData')).companytype == 3) {
        var childrenArrayInfo = {};
        childrenArrayInfo["id"] = 'Management';
        childrenArrayInfo["title"] = 'Management';
        childrenArrayInfo["type"] = 'collapsable';
        childrenArrayInfo["icon"] = 'menu';
        childrenArrayInfo["children"] = [
            {
                id: 'vessel-management',
                title: 'Vessel Management',
                type: 'item',
                icon: 'directions_boat',
                url: '/apps/vessel-management',
                exactMatch: true
            },
            {
                id: 'currency_managment',
                title: 'Currency Management',
                type: 'item',
                icon: 'money',
                url: '/apps/currency-managment',
                exactMatch: true
            },
            {
                id: 'charter assignment',
                title: 'Charterer Management',
                type: 'item',
                icon: 'assignment',
                url: '/apps/charterer-assignments',
                exactMatch: true
            },
            {
                id: 'owner_assignment',
                title: 'Owner Management',
                type: 'item',
                icon: 'assignment',
                url: '/apps/owner-assignments',
                exactMatch: true
            },
        ];
        childrenArray.push(childrenArrayInfo);

        // childrenArrayInfo["id"] = 'charter assignment';
        // childrenArrayInfo["title"] = 'Charterer Management';
        // childrenArrayInfo["type"] = 'item';
        // childrenArrayInfo["icon"] = 'assignment';
        // childrenArrayInfo["url"] = '/apps/charterer-assignments';
        // childrenArray.push(childrenArrayInfo);

        // var childrenArrayInfo = {};

        // childrenArrayInfo["id"] = 'owner_assignment';
        // childrenArrayInfo["title"] = 'Owner Management';
        // childrenArrayInfo["type"] = 'item';
        // childrenArrayInfo["icon"] = 'assignment';
        // childrenArrayInfo["url"] = '/apps/owner-assignments';
        // childrenArray.push(childrenArrayInfo);
    }

    if (JSON.parse(localStorage.getItem('userData')).companytype == 6) {
        var childrenArrayInfo = {};
        childrenArrayInfo["id"] = 'Management';
        childrenArrayInfo["title"] = 'Management';
        childrenArrayInfo["type"] = 'collapsable';
        childrenArrayInfo["icon"] = 'menu';
        childrenArrayInfo["children"] = [
            {
                id: 'vessel-management',
                title: 'Vessel Management',
                type: 'item',
                icon: 'directions_boat',
                url: '/apps/vessel-management',
                exactMatch: true
            },
            {
                id: 'currency_managment',
                title: 'Currency Management',
                type: 'item',
                icon: 'money',
                url: '/apps/currency-managment',
                exactMatch: true
            },
        ];

        childrenArray.push(childrenArrayInfo);
    }
}

if (JSON.parse(localStorage.getItem('userRoleId')) != '7') {
    if (JSON.parse(localStorage.getItem('userRoleId')) == '5') {
        var childrenArrayInfo = {};
        childrenArrayInfo["id"] = 'Platform Admin ';
        childrenArrayInfo["title"] = 'Support';
        childrenArrayInfo["type"] = 'collapsable';
        childrenArrayInfo["icon"] = 'speaker_notes';
        childrenArrayInfo["children"] = [

            // {
            //     id: 'contact',
            //     title: 'User Directory',
            //     type: 'item',
            //     icon: 'speaker_notes',
            //     url: '/apps/contact',
            //     exactMatch: true
            // },
            // {
            //     id: 'Notification',
            //     title: 'Notification',
            //     type: 'item',
            //     icon: 'notifications_active',
            //     url: '/apps/notification',
            //     exactMatch: true
            // },
            {
                id: 'Report Platform Bugs & Suggestions',
                title: 'Bugs & Suggestions',
                type: 'item',
                icon: 'speaker_notes',
                url: '/apps/platform-admin',
                exactMatch: true
            },
            {
                id: 'Frequently Asked Questions',
                title: "FAQ's",
                type: 'item',
                icon: 'notes',
                url: '/apps/faq-management',
                exactMatch: true
            },
            {
                id: 'Platform Terms & Conditions',
                title: 'Terms & Conditions',
                type: 'item',
                icon: 'speaker_notes',
                url: '/apps/term-management',
                exactMatch: true
            }



        ];
        childrenArray.push(childrenArrayInfo);
    }
}

if (JSON.parse(localStorage.getItem('userRoleId')) == '3') {
    // var childrenArrayInfo = {};
    // childrenArrayInfo["id"] = 'vessel-management';
    // childrenArrayInfo["title"] = 'Vessel Management';
    // childrenArrayInfo["translate"] = 'NAV.VESSEL';
    // childrenArrayInfo["type"] = 'item';
    // childrenArrayInfo["icon"] = 'directions_boat';
    // childrenArrayInfo["url"] = '/apps/vessel-management';

    // childrenArray.push(childrenArrayInfo);
    var childrenArrayInfo = {};
    childrenArrayInfo["id"] = 'Management';
    childrenArrayInfo["title"] = 'Management';
    childrenArrayInfo["type"] = 'collapsable';
    childrenArrayInfo["icon"] = 'menu';
    childrenArrayInfo["children"] = [
        {
            id: 'vessel-management',
            title: 'Vessel Management',
            type: 'item',
            icon: 'directions_boat',
            url: '/apps/vessel-management',
            exactMatch: true
        },
    ];
    childrenArray.push(childrenArrayInfo);

    // var childrenArrayInfo = {};

    // childrenArrayInfo["id"] = 'currency_managment';
    // childrenArrayInfo["title"] = 'Currency Management';
    // childrenArrayInfo["type"] = 'item';
    // childrenArrayInfo["icon"] = 'money';
    // childrenArrayInfo["url"] = '/apps/currency-managment';
    // childrenArray.push(childrenArrayInfo);
}

if (JSON.parse(localStorage.getItem('userRoleId')) == '6') {
    var childrenArrayInfo = {};
    childrenArrayInfo["id"] = 'Management';
    childrenArrayInfo["title"] = 'Management';
    childrenArrayInfo["type"] = 'collapsable';
    childrenArrayInfo["icon"] = 'menu';
    childrenArrayInfo["children"] = [
        {
            id: 'vessel-management',
            title: 'Vessel Management',
            type: 'item',
            icon: 'directions_boat',
            url: '/apps/vessel-management',
            exactMatch: true
        },
    ];
    childrenArray.push(childrenArrayInfo);
    // var childrenArrayInfo = {};
    // childrenArrayInfo["id"] = 'vessel-management';
    // childrenArrayInfo["title"] = 'Vessel Management';
    // childrenArrayInfo["translate"] = 'NAV.VESSEL';
    // childrenArrayInfo["type"] = 'item';
    // childrenArrayInfo["icon"] = 'directions_boat';
    // childrenArrayInfo["url"] = '/apps/vessel-management';

    // childrenArray.push(childrenArrayInfo);
}


export const navigation: FuseNavigation[] =
    [

        {
            id: '',
            title: '',
            translate: 'NAV.APPLICATIONS',
            type: 'group',
            icon: 'apps',
            children: childrenArray
        }
    ];
