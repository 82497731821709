import { NgModule } from '@angular/core';
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { TranslateModule } from '@ngx-translate/core';
import { SignaturePadModule } from 'angular2-signaturepad';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule } from 'ngx-mat-datetime-picker';
import { NgxTextDiffModule } from 'ngx-text-diff'
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { pushNotification } from './_services';
import { environment } from '../environments/environment';
import { AsyncPipe } from '../../node_modules/@angular/common';

import 'hammerjs';


// import { MatPaginator } from '@angular/material/paginator';
// import { MatTableDataSource } from '@angular/material/table';
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import {
    FuseProgressBarModule,
    FuseSidebarModule,
    FuseThemeOptionsModule
} from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { FakeDbService } from 'app/fake-db/fake-db.service';
import { AppComponent } from 'app/app.component';
import { AppStoreModule } from 'app/store/store.module';
import { LayoutModule } from 'app/layout/layout.module';
import { ToastrModule } from 'ngx-toastr';
import { ClauseCategoryTermsComponent } from './clause-category-terms/clause-category-terms.component';

import { CalligraphyPopupComponent } from './main/apps/calligraphy-popup/calligraphy-popup.component';
// import { SearchDropdownComponent } from './search-dropdown/search-dropdown.component';
// import { EditPlatformComponent } from './main/apps//platform-admin/edit-platform/edit-platform.component';
// import { AddPlatformAdminComponent } from './add-platform-admin/add-platform-admin.component';
// import { PlatformAdminComponent } from './platform-admin/platform-admin.component';

const appRoutes: Routes = [
    {
        path: 'apps',
        loadChildren: './main/apps/apps.module#AppsModule',
    },
    {
        path: 'pages',
        loadChildren: './main/pages/pages.module#PagesModule',
    },
    {
        path: '**',
        redirectTo: 'pages/auth/login',
    }
];

@NgModule({
    declarations: [AppComponent, ClauseCategoryTermsComponent, CalligraphyPopupComponent,],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        SignaturePadModule,
        NgxMaterialTimepickerModule,
        RouterModule.forRoot(appRoutes),
        // RouterModule.forRoot(appRoutes, { useHash: true }),
        ToastrModule.forRoot({timeOut: 6000}), // ToastrModule added
        TranslateModule.forRoot(),
        InMemoryWebApiModule.forRoot(FakeDbService, {
            delay: 0,
            passThruUnknownUrl: true
        }),
        // Material moment date module
        MatMomentDateModule,
        // Material
        MatButtonModule,
        MatIconModule,
        NgxMatDatetimePickerModule,
        NgxMatTimepickerModule,
        // MatFormFieldModule,
        // MatInputModule,
        // MatTableModule,
        // MatSortModule,
        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,
        NgxTextDiffModule,
        CKEditorModule,
        // App modules
        LayoutModule,
        AppStoreModule,
        AngularFireDatabaseModule,
        AngularFireAuthModule,
        AngularFireMessagingModule,
        AngularFireModule.initializeApp(environment.firebase),
    ],
    providers: [pushNotification, AsyncPipe, { provide: LocationStrategy, useClass: PathLocationStrategy }],
    entryComponents: [CalligraphyPopupComponent],

    bootstrap: [AppComponent]
})
export class AppModule { }
