import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from '../_models';
import { config } from '../config/config';
import { Crypto } from './crypto.service';

@Injectable({ providedIn: 'root' })

export class AuthenticationService {

  private currentUserSubject: BehaviorSubject<User>;

  public currentUser: Observable<User>;

  baseUrl = config.baseUrl;

  returnUrl: any;

  constructor(private http: HttpClient, private cryptoJS: Crypto, private router: Router, private route: ActivatedRoute) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
    let hasUser = localStorage.getItem('userData');
    if (hasUser) {
      this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/apps/dashboards/analytics';
      //   this.router.navigate([this.returnUrl]);
    }

  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }


  login(username, mobile, password, countryCOdeInfo) {

    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post<any>(`${this.baseUrl}/userslogin`,
      { info: this.cryptoJS.encryptData({ username, mobile, password, countryCOdeInfo }) },
      { headers: headers })
      .pipe(
        map(user => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('userData', JSON.stringify(user));
          // console.log('t',localStorage);
          this.currentUserSubject.next(user);
          return user;
        }));
  }

  sendOTP(id, countryCode, mobileNo) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post<any>(`${this.baseUrl}/otp`,
      { info: this.cryptoJS.encryptData({ id, countryCode, mobileNo }) },
      { headers: headers })
      .pipe(
        map(user => { return user; }));
  }

  verifyOTP(mobileNo, otpNo) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post<any>(`${this.baseUrl}/verifyOtp`,
      { info: this.cryptoJS.encryptData({ mobileNo, otpNo }) },
      { headers: headers }).pipe(
        map(user => { return user; }));
  }

  forgotPassword(email) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post<any>(`${this.baseUrl}/userforgotpassword`, { info: this.cryptoJS.encryptData({ email }) }, { headers: headers }).pipe(
      map(user => { return user; }));
  }

  resetPasswordData(resetToken, resetpassword) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post<any>(`${this.baseUrl}/resetPassword`, { info: this.cryptoJS.encryptData({ resetToken, resetpassword }) }, {
      headers: headers
    })
      .pipe(map(user => {
        return user;
      }));

  }

  userChangePassword(resetToken, resetpassword) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post<any>(`${this.baseUrl}/resetPassword`,
      { info: this.cryptoJS.encryptData({ resetToken, resetpassword }) },
      { headers: headers }).pipe(
        map(user => { return user; }));
  }

  changePassword(oldpassword, newpassword, id) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post<any>(`${this.baseUrl}/userchangepassword`,
      { info: this.cryptoJS.encryptData({ oldpassword, newpassword, id }) },
      { headers: headers }).pipe(
        map(user => { return user; }));
  }

  systemActivityAlert(userid) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post<any>(`${this.baseUrl}/systemActivityAlert`, { info: this.cryptoJS.encryptData({ userid }) }, { headers: headers }).pipe(
      map(user => { return user; }));
  }

  logout() {
    localStorage.removeItem('userToken');
    this.currentUserSubject.next(null);
    localStorage.clear();
    localStorage.clear();
    this.router.navigate(['/pages/auth/login']);
  }

  createTermDiff(payload) {
    return this.http.post(`${config.baseUrl}/termsActionInsert`, { info: this.cryptoJS.encryptData({ payload }) }).pipe(
      map(res => { return res; }));
  }

  readTermDiffs(param) {
    return this.http.post(`${config.baseUrl}/getTermsAction`, { info: this.cryptoJS.encryptData({ param }) }).pipe(
      map(res => { return res; }));
  }

}