export let config;
if (window.location.hostname == "localhost") {
    config = {
        // socketUrl: 'http://localhost:3002',
        // baseUrl: 'http://localhost:3002/api',
        // imageURL: "http://localhost:3002",
        socketUrl: 'https://deepblue.xceltec.in:3002',
        baseUrl: 'https://deepblue.xceltec.in:3002/api',
        imageURL: "https://deepblue.xceltec.in:3002",
        
        // socketUrl: 'https://staging.asbausa.org:3001',
        // baseUrl: 'https://staging.asbausa.org:3001/api',
        // imageURL: "https://staging.asbausa.org:3001",
        
        mainURL: 'https://deepblue.xceltec.in/',

        licenseKey: '7ktVhdbq5SmOage/VwfhH1p4p5bPsTxJon+zpamrCvzbN3ZqFcCTruA='
    }
}
else if (window.location.hostname == "deepblue.xceltec.in") {
    config = {
        socketUrl: 'https://deepblue.xceltec.in:3002',
        baseUrl: 'https://deepblue.xceltec.in:3002/api',
        imageURL: "https://deepblue.xceltec.in:3002",
        
        mainURL: 'https://deepblue.xceltec.in/',

        licenseKey: '7ktVhdbq5SmOage/VwfhH1p4p5bPsTxJon+zpamrCvzbN3ZqFcCTruA='
    }
}
else { 
    config = {
        socketUrl: 'https://staging.asbausa.org:3001/',
        baseUrl: 'https://staging.asbausa.org:3001/api',
        imageURL: "https://staging.asbausa.org:3001/",
        
        mainURL: 'https://staging.asbausa.org/',

        licenseKey: '7ktVhdbq5SmOage/VwfhH1p4p5bPsTxJon+zpamrCvzbN3ZqFcCTruA='
    } 
}