import { Component, ViewEncapsulation, OnInit, ElementRef, ViewChild } from '@angular/core';
import { fromEvent } from 'rxjs';

@Component({
    selector: 'content',
    templateUrl: './content.component.html',
    styleUrls: ['./content.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ContentComponent implements OnInit {
    /**
     * Constructor
     */
    showScroll: boolean;
    currentScroll = 0;
    showScrollHeight = 300;
    hideScrollHeight = 10;

    constructor() { }

    @ViewChild('div', { static: true }) _div: ElementRef;

    ngOnInit() {
        // fromEvent(this._div.nativeElement, 'scroll').subscribe((e: Event) => {
        //     this.currentScroll = e.target['scrollTop']
        //     if (this.currentScroll > this.showScrollHeight) {
        //         this.showScroll = true;
        //     }
        //     else if (this.showScroll && this.currentScroll < this.hideScrollHeight) {
        //         this.showScroll = false;
        //     }
        // });
    }

    scrollToTop() {
        if (this.currentScroll > 0) {
            document.getElementById('div').scrollIntoView(true);
        }
    }
}
