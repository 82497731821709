import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { config } from '../config/config';
import { Router } from '@angular/router';
import { Crypto } from './crypto.service';

@Injectable({ providedIn: 'root' })
export class UserService {

    public headers;

    public token;

    constructor(private http: HttpClient, private router: Router, private cryptoJS: Crypto) {
        this.setHeader();
    }

    setHeader() {
        this.token = localStorage.getItem('userToken');
        this.headers = new HttpHeaders().set('authorization', 'bearer ' + this.token);
    }

    vesselRecordsServerSide() {
        return this.http.get(`${config.baseUrl}/vesselRecordsServerSide`,
            { headers: this.headers });
    }

    DrawStatusData(req) {
        return this.http.post(`${config.baseUrl}/DrawStatusData`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    clausesCategoryRecords() {
        // console.log('here in clauses Categoory services');
        return this.http.get(`${config.baseUrl}/clusesCategorylist`)
    }

    getUserList(id) {
        return this.http.get(`${config.baseUrl}/userList/` + id,
            { headers: this.headers });
    }

    getCompanyList() {
        return this.http.get(`${config.baseUrl}/companylist`,
            { headers: this.headers });
    }

    getUsersCompanyList(req) {
        return this.http.post(`${config.baseUrl}/getUsersCompanyList`, req);
    }

    getChartererList() {
        return this.http.get(`${config.baseUrl}/charterlist`);
    }
    getbrokererList() {
        return this.http.get(`${config.baseUrl}/AllBrokerlist`);
    }
    addCompanyList(req) {
        return this.http.post(`${config.baseUrl}/companycreate`, req);
    }
    updateCompanyList(req) {
        return this.http.post(`${config.baseUrl}/companyupdate`, req);
    }
    updateCompanyadminList(req) {
        return this.http.post(`${config.baseUrl}/companyadminupdate`, req);
    }

    updateUserManagement(req) {
        return this.http.post(`${config.baseUrl}/userupdate`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    getCompanyadminList() {
        return this.http.get(`${config.baseUrl}/companyadminlist`);
    }
    updateCharterer(req) {
        return this.http.post(`${config.baseUrl}/charterupdate`, req);
    }
    getChartererDetail(req) {
        return this.http.post(`${config.baseUrl}/charterdetails`, req);
    }
    getbrokererDetail(req) {
        return this.http.post(`${config.baseUrl}/BrokerDetails`, req);
    }

    getvesselDetail(req) {
        return this.http.post(`${config.baseUrl}/vesseldetails`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    VesselList() {
        return this.http.get(`${config.baseUrl}/vessellist`,
            { headers: this.headers });
    }

    tempVesselList() {
        return this.http.get(`${config.baseUrl}/tempvessellist`,
            { headers: this.headers });
    }

    getactivitylist() {
        return this.http.get(`${config.baseUrl}/vesselactivity`,
            { headers: this.headers });
    }

    AddVessel(req) {
        return this.http.post(`${config.baseUrl}/vesseladd`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    getstateList() {
        return this.http.get(`${config.baseUrl}/vesselbase`,
            { headers: this.headers });
    }

    getbuilderList() {
        return this.http.get(`${config.baseUrl}/vesselbuilder`,
            { headers: this.headers });
    }

    getflagList() {
        return this.http.get(`${config.baseUrl}/vesselflag`,
            { headers: this.headers });
    }

    getstatusList() {
        return this.http.get(`${config.baseUrl}/vesselstatus`,
            { headers: this.headers });
    }

    getcommmentList() {
        return this.http.get(`${config.baseUrl}/vesselcomment`,
            { headers: this.headers });
    }

    getenginedesignList() {
        return this.http.get(`${config.baseUrl}/auxenginedesignlist`,
            { headers: this.headers });
    }

    getenginetypeList() {
        return this.http.get(`${config.baseUrl}/auxenginetypelist`,
            { headers: this.headers });
    }

    getfueltypeList() {
        return this.http.get(`${config.baseUrl}/fueltypeslist`,
            { headers: this.headers });
    }

    getnationalityList() {
        return this.http.get(`${config.baseUrl}/nationalitylist`,
            { headers: this.headers });
    }

    getvesseltypeeList() {
        return this.http.get(`${config.baseUrl}/shiptypelist`,
            { headers: this.headers });
    }

    getcompanyList() {
        return this.http.get(`${config.baseUrl}/companylist`,
            { headers: this.headers });
    }

    getregistryList() {
        return this.http.get(`${config.baseUrl}/portregistrylist`,
            { headers: this.headers });
    }

    addSubAlert(req) {
        return this.http.post(`${config.baseUrl}/subcategoryAdd`, req);
    }

    deleteVessel(req) {
        return this.http.post(`${config.baseUrl}/vesseldelete`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    temptabledeleteVessel(req) {
        return this.http.post(`${config.baseUrl}/temptabledeleteVessel`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    updateVessel(req) {
        return this.http.post(`${config.baseUrl}/vesselupdate`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    updateanddelete(req) {
        return this.http.post(`${config.baseUrl}/updateanddelete`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    tempAddVessel(req) {
        return this.http.post(`${config.baseUrl}/tempvesseladd`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    addFile(req) {
        return this.http.post(`${config.baseUrl}/fileupload`,
            req, { headers: this.headers });
    }

    addprofile(id, req) {
        return this.http.post(`${config.baseUrl}/image_uplaod/` + id,
            req, { headers: this.headers });
    }

    roleActiveInactive(req) {
        return this.http.post(`${config.baseUrl}/userroleactive`, req);
    }
    selectList(req) {
        return this.http.post(`${config.baseUrl}/selectrolelist`, req);
    }
    FrontendselectroleList(req) {
        return this.http.post(`${config.baseUrl}/FrontendselectroleList`, req);
    }
    moduleAction(req) {
        return this.http.post(`${config.baseUrl}/moduleactioncreate`, req);
    }

    FrontendselectList(req) {
        return this.http.post(`${config.baseUrl}/FrontendroleAssign`, req);
    }
    FrontendmoduleAction(req) {
        return this.http.post(`${config.baseUrl}/FrontendmoduleactionCreate`, req);
    }

    PositionList() {
        return this.http.get(`${config.baseUrl}/vesselpositionlist`,
            { headers: this.headers })
    }

    roleListshow() {
        return this.http.get(`${config.baseUrl}/roleAccessmoduleView`)
    }
    FrontendroleListshow() {
        return this.http.get(`${config.baseUrl}/FrontendroleActionView`)
    }

    /* currency managment */

    getCurrency() {
        return this.http.get(`${config.baseUrl}/getCurrencys`,
            { headers: this.headers })
    }

    getCompanyCurrencys(req) {
        return this.http.post(`${config.baseUrl}/getCompanyCurrencys`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    actionOnCompanyCurrency(req) {
        return this.http.post(`${config.baseUrl}/actionOnCompanyCurrency`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }


    // charter party type

    getcharterpartytypeList() {
        return this.http.get(`${config.baseUrl}/charterpartylist`,
            { headers: this.headers });
    }

    getcharterpartytypeadd(req) {
        return this.http.post(`${config.baseUrl}/charterpartytypecreate`, req);
    }
    getcharterpartytypeupdate(req) {
        return this.http.post(`${config.baseUrl}/charterpartypeupdate`, req);
    }
    getcharterpartytypedelete(req) {
        return this.http.post(`${config.baseUrl}/charterpartytypedelete`, req);
    }



    // cluses category detail

    getclusesCategoryList() {
        return this.http.get(`${config.baseUrl}/clusesCategorylist`)
    }

    getclusesCategoryadd(req) {
        return this.http.post(`${config.baseUrl}/clusesCategorycreate`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    getclusesCategoryupdate(req) {
        return this.http.post(`${config.baseUrl}/clusesCategoryupdate`, req);
    }

    getclusesCategorydelete(req) {
        return this.http.post(`${config.baseUrl}/clusesCategorydelete`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }


    // cluses term detail

    getclusesList() {
        return this.http.get(`${config.baseUrl}/cluseslist`, { headers: this.headers })
    }
    getclusesadd(req) {
        return this.http.post(`${config.baseUrl}/clusescreate`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }
    getclusesupdate(req) {
        return this.http.post(`${config.baseUrl}/clusesupdate`, req);
    }
    getclusesdelete(req) {
        return this.http.post(`${config.baseUrl}/clusesdelete`, req);
    }


    //Cp Form 

    getFormList() {
        return this.http.get(`${config.baseUrl}/cpFromlist`,
            { headers: this.headers });
    }

    getFormadd(req) {
        return this.http.post(`${config.baseUrl}/cpFromcreate`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    getFormedit(req) {
        return this.http.post(`${config.baseUrl}/cpFromupdate`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    getFormdelete(req) {
        return this.http.post(`${config.baseUrl}/cpFromdelete`, req)
    }

    // Draw Form Routes
    // Draw Form Records Service
    drawFormRecords() {
        // console.log('here in user services');
        return this.http.get(`${config.baseUrl}/drawFormRecords`)
    }
    //  Draw Records Server Side
    drawRecordsServerSide(req) {
        // console.log(req);
        return this.http.post(`${config.baseUrl}/drawRecordsServerSide`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    drawRecordsServerSidedatalist(req) {
        // console.log(req);
        return this.http.post(`${config.baseUrl}/drawRecordsServerSidedatalist`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    //  Draw Records Server Side
    drawRecordsServerSideforfilter(req) {
        // console.log(req);
        return this.http.post(`${config.baseUrl}/drawRecordsServerSideforfilter`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    /** New changes */
    tradingRecordsServerSide(req) {
        // console.log(req);
        return this.http.post(`${config.baseUrl}/tradingRecordsServerSide`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    tradingRecordsServerSidedatalist(req) {
        // console.log(req);
        return this.http.post(`${config.baseUrl}/tradingRecordsServerSidedatalist`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    TradeFormCopyCreate(req) {
        // console.log(req);
        return this.http.post(`${config.baseUrl}/TradeFormCopyCreate`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    tradingRecordsServerSideSerach(req) {
        // console.log(req);
        return this.http.post(`${config.baseUrl}/tradingRecordsServerSideSerach`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    getcustomclaueseCategorycreate(req) {
        // console.log(req);
        return this.http.post(`${config.baseUrl}/getcustomclaueseCategorycreate`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    getcustomtermCategorycreate(req) {
        // console.log(req);
        return this.http.post(`${config.baseUrl}/getcustomtermCategorycreate`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    getTermupdateDetails(req) {
        // console.log(req);
        return this.http.post(`${config.baseUrl}/getTermupdateDetails`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    tradingRecordsServerSideforfilter(req) {
        // console.log(req);
        return this.http.post(`${config.baseUrl}/tradingRecordsServerSideforfilter`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    fetchingtradeData(req) {
        // console.log(req);
        return this.http.post(`${config.baseUrl}/fetchingtradeData`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    tradeupdateCheckedClauses(req) {
        // console.log(req);
        return this.http.post(`${config.baseUrl}/tradeupdateCheckedClauses`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    tradinggetStaticFormData(req) {
        // console.log(req);
        return this.http.post(`${config.baseUrl}/tradinggetStaticFormData`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    TradeDataSignatureUpadate(req) {
        // console.log(req);
        return this.http.post(`${config.baseUrl}/TradeDataSignatureUpadate`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    Tradesubjectlifingclause(req) {
        // console.log(req);
        return this.http.post(`${config.baseUrl}/Tradesubjectlifingclause`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    drawRecordsServerSideSerach(req) {
        // console.log(req);
        return this.http.post(`${config.baseUrl}/drawRecordsServerSideSerach`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    // Draw Form Create Service
    DrawFormCreate(req) {
        return this.http.post(`${config.baseUrl}/DrawFormCreate`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    tradeFormCreate(req) {
        return this.http.post(`${config.baseUrl}/tradeFormCreate`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    // Draw Form Update Service
    drawFormUpdate(req) {
        return this.http.post(`${config.baseUrl}/drawFormUpdate`, req)
    }

    drawFormUpdateByCharter(req) {
        return this.http.post(`${config.baseUrl}/drawFormUpdateByCharterCheck`, req)
    }

    drawFormUpdateBybroker(req) {
        return this.http.post(`${config.baseUrl}/drawFormUpdateByBrokerCheck`, req)
    }

    drawFormUpdateByowner(req) {
        return this.http.post(`${config.baseUrl}/drawFormUpdateByOwnerCheck`, req)
    }

    DrawShare(req) {
        return this.http.post(`${config.baseUrl}/DrawShare`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    DrawLock(req) {
        return this.http.post(`${config.baseUrl}/DrawLock`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    DrawShareRevoke(req) {
        return this.http.post(`${config.baseUrl}/DrawShareRevoke`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }
    // Draw Form Remove Service
    drawDataRemove(req) {
        return this.http.post(`${config.baseUrl}/drawDataRemove`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    // CityForm Routes
    // CityForm Records Service
    CityRecords() {
        return this.http.get(`${config.baseUrl}/CityRecords`,
            { headers: this.headers });
    }

    CityDrawRecords(req) {
        return this.http.post(`${config.baseUrl}/CityDrawRecords`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    // CityForm Create Service
    CityCreate(req) {
        return this.http.post(`${config.baseUrl}/CityCreate`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }
    // CityForm Update Service
    CityUpdate(req) {
        return this.http.post(`${config.baseUrl}/CityUpdate`, req)
    }
    // CityForm Remove Service
    CityRemove(req) {
        return this.http.post(`${config.baseUrl}/CityRemove`, req)
    }

    // Draw Invite Routes
    // Draw Invite Records Server Side
    DrawInviteRecordsServerSide(req) {
        // console.log(req);
        return this.http.post(`${config.baseUrl}/DrawInviteRecordsServerSide`, req)
    }

    drawRecordsServerSideArchived(req) {
        // console.log(req);
        return this.http.post(`${config.baseUrl}/drawRecordsServerSideArchived`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    retriveDraw(req) {
        return this.http.post(`${config.baseUrl}/retrivedrawRecored`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    // Draw Invite Form Create Service
    DrawInviteCreate(req) {
        return this.http.post(`${config.baseUrl}/DrawInviteCreate`, req)
    }
    // Draw Invite Form Update Service
    DrawInviteUpdate(req) {
        return this.http.post(`${config.baseUrl}/DrawInviteUpdate`, req)
    }
    // Draw Invite Form Remove Service
    DrawInviteRemove(req) {
        return this.http.post(`${config.baseUrl}/DrawInviteRemove`, req)
    }



    //  ********************   // clause amendment

    // clause amendment  add
    customeClauseadd(req) {
        return this.http.post(`${config.baseUrl}/clauseamendmentCreate`, req)
    }
    customeClauseuUpdate(req) {
        return this.http.post(`${config.baseUrl}/clauseamendmentUpdate`, req)
    }
    customeClauseremove(req) {
        return this.http.post(`${config.baseUrl}/clauseamendmentRemove`, req)
    }

    customeClauseList() {
        return this.http.get(`${config.baseUrl}/clauseamendmentlist`)
    }

    getplateformList(req) {
        return this.http.post(`${config.baseUrl}/platformAdminlist`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    plateformStatusUpdate(req) {
        return this.http.post(`${config.baseUrl}/plateformStatusUpdate`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    platformAdminCreate(req) {
        return this.http.post(`${config.baseUrl}/platformAdminCreate`,
            { info: this.cryptoJS.encryptData(req) }, { headers: this.headers });
    }

    clauseCategoryServerSideRecordsServerSide(req) {
        return this.http.post(`${config.baseUrl}/clauseCategoryServerSideRecords`,
            { info: this.cryptoJS.encryptData(req) }, { headers: this.headers });
    }

    // Clause Terms Data Records Server Side

    clauseTermsDetailsRecordsServerSide(req) {
        return this.http.post(`${config.baseUrl}/clauseTermsDetailsRecordsServerSide`, req)
    }

    // Clause Reviews Records Server Side
    clauseTermsReviewsRecordsServerSide(req) {
        return this.http.post(`${config.baseUrl}/clauseTermsReviewsRecordsServerSide`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    // Clause Reviews Records Server Side Custom
    clauseTermsReviewsRecordsServerSideCustom(req) {
        return this.http.post(`${config.baseUrl}/clauseTermsReviewsRecordsServerSideCustom`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    clauseTermsUpdate(req) {
        return this.http.post(`${config.baseUrl}/claueseDetailInsertUpdate`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    claueseapproveInsertUpdate(req) {
        return this.http.post(`${config.baseUrl}/claueseapproveInsertUpdate`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    drawCommentCreate(req) {
        return this.http.post(`${config.baseUrl}/drawCommentCreate`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    tradingCommentCreate(req) {
        return this.http.post(`${config.baseUrl}/tradingCommentCreate`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    drawcpCommentCreate(req) {
        return this.http.post(`${config.baseUrl}/drawcpCommentCreate`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    tradeCommentCreate(req) {
        return this.http.post(`${config.baseUrl}/tradeCommentCreate`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    clauseCustomeTermsUpdate(req) {
        return this.http.post(`${config.baseUrl}/claueseDetailCustomInsertUpdate`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    // Clause Terms Review Insert Update
    claueseDetailInsertUpdate(req) {
        return this.http.post(`${config.baseUrl}/claueseDetailInsertUpdate`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    // Clause Category Records Server Side
    clauseCategoryServerSideRecords(req) {
        return this.http.post(`${config.baseUrl}/clauseCategoryServerSideRecords`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    // Notification Create
    notificationCreate(req) {
        return this.http.post(`${config.baseUrl}/notificationCreate`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }
    // Notification Create
    notificationStatusUpdate(req) {
        return this.http.post(`${config.baseUrl}/notificationStatusUpdate`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    // Notification Records
    notificationRecords(req) {
        return this.http.post(`${config.baseUrl}/notificationRecords`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    drawmobileNotification(req) {
        return this.http.post(`${config.baseUrl}/drawmobileNotification`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    // news and Notification Records
    newsnotificationRecords() {
        return this.http.get(`${config.baseUrl}/newsList`)
    }

    // Charter Data Accept / Reject
    CharterDrawAcceptReject(req) {
        return this.http.post(`${config.baseUrl}/CharterDrawAcceptReject`, req)
    }

    // Charter Data Accept / Reject Status Update
    charterPartyRequestStatusUpdate(req) {
        return this.http.post(`${config.baseUrl}/charterPartyRequestStatusUpdate`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers })
    }

    // Draw Setver Side Records For Charterer
    drawRecordsServerSideCharterer(req) {
        return this.http.post(`${config.baseUrl}/drawRecordsServerSideCharterer`, req)
    }

    drawCommentsRecordsServerSide(req) {
        return this.http.post(`${config.baseUrl}/drawCommentsRecordsServerSide`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    tradingCommentsRecordsServerSide(req) {
        return this.http.post(`${config.baseUrl}/tradingCommentsRecordsServerSide`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    TradecheckallclauseUpdate(req) {
        return this.http.post(`${config.baseUrl}/TradecheckallclauseUpdate`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    commentsReaded(req) {
        return this.http.post(`${config.baseUrl}/commentsReaded`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    customCommentCreate(req) {
        return this.http.post(`${config.baseUrl}/customCommentCreate`, req)
    }

    customCommentsRecordsServerSide(req) {
        return this.http.post(`${config.baseUrl}/customCommentsRecordsServerSide`, req)
    }
    riderCommentCreate(req) {
        return this.http.post(`${config.baseUrl}/riderCommentCreate`, req)
    }

    riderCommentsRecordsServerSide(req) {
        return this.http.post(`${config.baseUrl}/riderCommentsRecordsServerSide`, req)
    }

    drawCpPreamblecomment(req) {
        return this.http.post(`${config.baseUrl}/drawCpPreamblecomment`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    drawCpPreamblecommentStatus(req) {
        return this.http.post(`${config.baseUrl}/drawCpPreamblecommentStatus`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }


    /** New changes */

    tradePreamblecomment(req) {
        return this.http.post(`${config.baseUrl}/tradePreamblecomment`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    tradePreamblecommentStatus(req) {
        return this.http.post(`${config.baseUrl}/tradePreamblecommentStatus`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    TradeShare(req) {
        return this.http.post(`${config.baseUrl}/TradeShare`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    TradeShareRevoke(req) {
        return this.http.post(`${config.baseUrl}/TradeShareRevoke`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    tradeNotificationInsert(req) {
        return this.http.post(`${config.baseUrl}/tradeNotificationInsert`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    drawFormCopyRecords(req) {
        return this.http.post(`${config.baseUrl}/drawFormCopyRecords`, req)
    }

    companyRecordsServerSide(req) {
        return this.http.post(`${config.baseUrl}/companyRecordsServerSide`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    fetchTradingStdBidReady(req) {
        return this.http.post(`${config.baseUrl}/fetchTradingStdBidReady`, req)
    }

    DrawFormCopyCreate(req) {
        return this.http.post(`${config.baseUrl}/DrawFormCopyCreate`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }
    // Draw Request Create
    DrawRequestToChartererCreate(req) {
        return this.http.post(`${config.baseUrl}/DrawRequestToChartererCreate`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    // Clause Category Upload
    clauseCategoryUpload(req) {
        return this.http.post(`${config.baseUrl}/clauseCategoryUpload`, req)
    }

    // Draw Request Create
    mainClauseScreenDataRecords(req) {
        return this.http.post(`${config.baseUrl}/mainClauseScreenDataRecords`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    commentClauseScreenDataRecords(req) {
        return this.http.post(`${config.baseUrl}/commentClauseScreenDataRecords`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    revisionClauseScreenDataRecords(req) {
        return this.http.post(`${config.baseUrl}/revisionClauseScreenDataRecords`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    tradedeleteriderClauses(req) {
        return this.http.post(`${config.baseUrl}/tradedeleteriderClauses`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    // Draw Request Create
    mainClauseScreenDataRecordsTrading(req) {
        return this.http.post(`${config.baseUrl}/mainClauseScreenDataRecordsTrading`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    CollectTermCommonclasue(req) {
        return this.http.post(`${config.baseUrl}/CollectTermCommonclasue`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    mainuncheckedClauseScreenDataRecordsTrading(req) {
        return this.http.post(`${config.baseUrl}/mainuncheckedClauseScreenDataRecordsTrading`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    revisionuncheckedClauseScreenDataRecordstrading(req) {
        return this.http.post(`${config.baseUrl}/revisionuncheckedClauseScreenDataRecordstrading`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    commentuncheckedClauseScreenDataRecordstrading(req) {
        return this.http.post(`${config.baseUrl}/commentuncheckedClauseScreenDataRecordstrading`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    ChangeTermCustomclasue(req) {
        return this.http.post(`${config.baseUrl}/ChangeTermCustomclasue`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    ChangeTermCommonclasue(req) {
        return this.http.post(`${config.baseUrl}/ChangeTermCommonclasue`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    OnlyApproveClauseScreenDataRecordstrading(req) {
        return this.http.post(`${config.baseUrl}/OnlyApproveClauseScreenDataRecordstrading`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    commentClauseScreenDataRecordstrading(req) {
        return this.http.post(`${config.baseUrl}/commentClauseScreenDataRecordstrading`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    revisionClauseScreenDataRecordstrading(req) {
        return this.http.post(`${config.baseUrl}/revisionClauseScreenDataRecordstrading`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    // Trading Platform Routes
    TradingFormRecords() {
        return this.http.get(`${config.baseUrl}/TradingFormRecords`)
    }

    TradingFormCreate(req) {
        return this.http.post(`${config.baseUrl}/TradingFormCreate`, req)
    }

    tradeStatusData(req) {
        return this.http.post(`${config.baseUrl}/tradeStatusData`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers })
    }

    tradeCancel(req) {
        return this.http.post(`${config.baseUrl}/tradeCancel`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    TradingFormRecordsServerSide(req) {
        return this.http.post(`${config.baseUrl}/TradingFormRecordsServerSide`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    userFindRecords(req) {
        return this.http.post(`${config.baseUrl}/userFindRecords`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    TradingFormUpdate(req) {
        return this.http.post(`${config.baseUrl}/TradingFormUpdate`, req)
    }

    TradingPlatformDataRemove(req) {
        return this.http.post(`${config.baseUrl}/TradingPlatformDataRemove`, req)
    }

    TradingPlatformAcceptReject(req) {
        return this.http.post(`${config.baseUrl}/TradingPlatformAcceptReject`, req)
    }

    TradingPlatformRequestToChartererCreate(req) {
        return this.http.post(`${config.baseUrl}/TradingPlatformRequestToChartererCreate`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    TradingPlatformRequestStatusUpdate(req) {
        return this.http.post(`${config.baseUrl}/TradingPlatformRequestStatusUpdate`, req)
    }

    TradingPlatformRecordsServerSideCharterer(req) {
        return this.http.post(`${config.baseUrl}/TradingPlatformRecordsServerSideCharterer`, req)
    }

    TradingCounterInsert(req) {
        return this.http.post(`${config.baseUrl}/TradingCounterInsert`, req)
    }

    getCounterNumber(req) {
        return this.http.post(`${config.baseUrl}/getCounterNumber`, req)
    }

    TradingData(req) {
        return this.http.post(`${config.baseUrl}/TradingData`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    CustomClauseInsert(req) {
        return this.http.post(`${config.baseUrl}/CustomClauseInsert`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    CustomClauseTermsInsert(req) {
        return this.http.post(`${config.baseUrl}/CustomClauseTermsInsert`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    customClauseRecords(req) {
        return this.http.post(`${config.baseUrl}/customClauseRecords`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    customClauseRecordsfortrading(req) {
        return this.http.post(`${config.baseUrl}/customClauseRecordsfortrading`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    TradeStatusInfoUpdate(req) {
        return this.http.post(`${config.baseUrl}/TradeStatusInfoUpdate`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    getCustomTermDataOfCustomClause(req) {
        return this.http.post(`${config.baseUrl}/getCustomTermDataOfCustomClause`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    CustomClauseTermsUpdateParentID(req) {
        return this.http.post(`${config.baseUrl}/CustomClauseTermsUpdateParentID`, req)
    }

    viewCustomTermDataOfCustomClause(req) {
        return this.http.post(`${config.baseUrl}/viewCustomTermDataOfCustomClause`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    getClauseTermDataForUpdate(req) {
        return this.http.post(`${config.baseUrl}/getClauseTermDataForUpdate`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    viewClauseTermUpdateRecordsOfMainClause(req) {
        return this.http.post(`${config.baseUrl}/viewClauseTermUpdateRecordsOfMainClause`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    getCustomClauseTermDataForUpdate(req) {
        return this.http.post(`${config.baseUrl}/getCustomClauseTermDataForUpdate`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    customClauseDetailsInsert(req) {
        return this.http.post(`${config.baseUrl}/customClauseDetailsInsert`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    viewCustomClauseTermUpdateRecordsOfMainClause(req) {
        return this.http.post(`${config.baseUrl}/viewCustomClauseTermUpdateRecordsOfMainClause`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    drawProgressUpdate(req) {
        return this.http.post(`${config.baseUrl}/drawProgressUpdate`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    tradingProgressUpdate(req) {
        return this.http.post(`${config.baseUrl}/tradingProgressUpdate`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    drawStatusInfoUpdate(req) {
        return this.http.post(`${config.baseUrl}/drawStatusInfoUpdate`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    tradingStatusInfoUpdate(req) {
        return this.http.post(`${config.baseUrl}/tradingStatusInfoUpdate`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    drawDataUpdate(req) {
        return this.http.post(`${config.baseUrl}/drawDataUpdate`, req)
    }

    fetchDrawData(req) {
        return this.http.post(`${config.baseUrl}/fetchDrawData`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    fetchTradingData(req) {
        return this.http.post(`${config.baseUrl}/fetchTradingData`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    tradingDataUpdate(req) {
        return this.http.post(`${config.baseUrl}/tradingDataUpdate`, req)
    }

    getCustomSubject(req) {
        return this.http.post(`${config.baseUrl}/getCustomSubjects`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    addCustomSubjects(req) {
        return this.http.post(`${config.baseUrl}/addCustomSubjects`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    deleteTrade(req) {
        return this.http.post(`${config.baseUrl}/deleteTrade`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }
    newTradeShare(req) {
        return this.http.post(`${config.baseUrl}/newTradeShare`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    fetchCompanyData(req) {
        return this.http.post(`${config.baseUrl}/fetchCompanyData`, req)
    }

    updateCheckedClauses(req) {
        return this.http.post(`${config.baseUrl}/updateCheckedClauses`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    getStaticFormData(req) {
        return this.http.post(`${config.baseUrl}/getStaticFormData`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    addStaticFormData(req) {
        return this.http.post(`${config.baseUrl}/addStaticFormData`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    getclasueRivision(req) {
        return this.http.post(`${config.baseUrl}/getclasueRivision`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    removeclasueRivision(req) {
        return this.http.post(`${config.baseUrl}/removeclasueRivision`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    tradingaddStaticFormData(req) {
        return this.http.post(`${config.baseUrl}/tradingaddStaticFormData`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    clauseCategoryRecordsServerSide(req) {
        return this.http.post(`${config.baseUrl}/clauseCategoryRecordsServerSide`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    getclauseCategoryRecordsServerSide(req) {
        return this.http.post(`${config.baseUrl}/getclauseCategoryRecordsServerSide`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    cpFormData(req) {
        return this.http.post(`${config.baseUrl}/cpFormData`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    fetchVesselData(req) {
        return this.http.post(`${config.baseUrl}/fetchVesselData`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    customInputDrawDataUpdate(req) {
        return this.http.post(`${config.baseUrl}/customInputDrawDataUpdate`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    updateCheckedClausesTrading(req) {
        return this.http.post(`${config.baseUrl}/updateCheckedClausesTrading`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    customInputTradingDataUpdate(req) {
        return this.http.post(`${config.baseUrl}/customInputTradingDataUpdate`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    clauseCategoryStatusUpdate(req) {
        return this.http.post(`${config.baseUrl}/clauseCategoryStatusUpdate`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    cpFormStatusUpdate(req) {
        return this.http.post(`${config.baseUrl}/cpFormStatusUpdate`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    clauseTermsStatusUpdate(req) {
        return this.http.post(`${config.baseUrl}/clauseTermsStatusUpdate`, req)
    }

    // Owner Routes

    OwnerRecords() {
        return this.http.get(`${config.baseUrl}/OwnerRecords`)
    }

    OwnerCreate(req) {
        return this.http.post(`${config.baseUrl}/OwnerCreate`, req)
    }

    OwnerDetails(req) {
        return this.http.post(`${config.baseUrl}/OwnerDetails`, req)
    }

    OwnerEdit(req) {
        return this.http.post(`${config.baseUrl}/OwnerEdit`, req)
    }

    OwnerDelete(req) {
        return this.http.post(`${config.baseUrl}/OwnerDelete`, req)
    }

    signatureFileUpload(req) {
        return this.http.post(`${config.baseUrl}/signatureFileUpload`, req)
    }

    drawDataSignatureUpadate(req) {
        return this.http.post(`${config.baseUrl}/drawDataSignatureUpadate`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    tradingDataSignatureUpadate(req) {
        return this.http.post(`${config.baseUrl}/tradingDataSignatureUpadate`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    userRecordsServerSide(req) {
        return this.http.post(`${config.baseUrl}/userRecordsServerSide`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    companyuserRecordsServerSide(req) {
        return this.http.post(`${config.baseUrl}/companyuserRecordsServerSide`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    brokeruserRecordsServerSide(req) {
        return this.http.post(`${config.baseUrl}/brokeruserRecordsServerSide`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    getAssignmentCompanyUsers(req) {
        return this.http.post(`${config.baseUrl}/getAssignmentCompanyUsers`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    TradingStandardFormCreate(req) {
        return this.http.post(`${config.baseUrl}/TradingStandardFormCreate`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    tradingEmailIDAndNotificationSend(req) {
        return this.http.post(`${config.baseUrl}/tradingEmailIDAndNotificationSend`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    getCompanyName(req) {
        return this.http.post(`${config.baseUrl}/getCompanyName`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    drawDataUpdateCommon(req) {
        return this.http.post(`${config.baseUrl}/drawDataUpdateCommon`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    tradeDataUpdateCommon(req) {
        return this.http.post(`${config.baseUrl}/tradeDataUpdateCommon`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    approveEmailNotification(req) {
        return this.http.post(`${config.baseUrl}/approveEmailNotification`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    commentEmailNotification(req) {
        return this.http.post(`${config.baseUrl}/commentEmailNotification`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    completeDrawNotification(req) {
        return this.http.post(`${config.baseUrl}/completeDrawNotification`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    userDataUpdateCommon(req) {
        return this.http.post(`${config.baseUrl}/userDataUpdateCommon`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }


    sendNotificationToCharterer(req) {
        return this.http.post(`${config.baseUrl}/sendNotificationToCharterer`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    TradingPlatformRequestStatusUpdateCommon(req) {
        return this.http.post(`${config.baseUrl}/TradingPlatformRequestStatusUpdateCommon`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    updateChartererToTrade(req) {
        return this.http.post(`${config.baseUrl}/updateChartererToTrade`, req)
    }

    updateOwnerToTrade(req) {
        return this.http.post(`${config.baseUrl}/updateOwnerToTrade`, req)
    }

    updateChartererToDraw(req) {
        return this.http.post(`${config.baseUrl}/updateChartererToDraw`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    messageCenterCreate(req) {
        return this.http.post(`${config.baseUrl}/messageCenterCreate`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    messageCenterRecordsServerSide(req) {
        return this.http.post(`${config.baseUrl}/messageCenterRecordsServerSide`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    messageCenterDataUpdate(req) {
        return this.http.post(`${config.baseUrl}/messageCenterDataUpdate`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    // Chat Management Routes
    chatCreate(req) {
        return this.http.post(`${config.baseUrl}/chatCreate`, req)
    }

    chatRecordsServerSide(req) {
        return this.http.post(`${config.baseUrl}/chatRecordsServerSide`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    fetchChatDetails(req) {
        return this.http.post(`${config.baseUrl}/fetchChatDetails`, req)
    }

    chatDataUpdate(req) {
        return this.http.post(`${config.baseUrl}/chatDataUpdate`, req)
    }

    fetchRealTimeChatData(req) {
        return this.http.post(`${config.baseUrl}/fetchRealTimeChatData`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    realTimeChatRecordsServerSide(req) {
        return this.http.post(`${config.baseUrl}/realTimeChatRecordsServerSide`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    faqlist() {
        return this.http.get(`${config.baseUrl}/faqlistactive`,
            { headers: this.headers });
    }

    tclist() {
        return this.http.get(`${config.baseUrl}/tclistactive`,
            { headers: this.headers })
    }


    tradingMessageInsert(req) {
        return this.http.post(`${config.baseUrl}/tradingMessageInsert`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    tradingProgressInsert(req) {
        return this.http.post(`${config.baseUrl}/tradingProgressInsert`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    tradingDataUpdateCommon(req) {
        return this.http.post(`${config.baseUrl}/tradingDataUpdateCommon`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    tradingNotificationInsert(req) {
        return this.http.post(`${config.baseUrl}/tradingNotificationInsert`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    copyTradingData(req) {
        return this.http.post(`${config.baseUrl}/copyTradingData`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    chartererInviteOwnerForTrade(req) {
        return this.http.post(`${config.baseUrl}/chartererInviteOwnerForTrade`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    clauseCategoryRecordsServerSideTrading(req) {
        return this.http.post(`${config.baseUrl}/clauseCategoryRecordsServerSideTrading`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    clauseTermsRecordsServerSide(req) {
        return this.http.post(`${config.baseUrl}/clauseTermsRecordsServerSide`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    ownerInviteChartererForTrade(req) {
        return this.http.post(`${config.baseUrl}/ownerInviteChartererForTrade`, req)
    }

    tradingProgressRecordsServerSide(req) {
        return this.http.post(`${config.baseUrl}/tradingProgressRecordsServerSide`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    newUsersRecords(req) {
        return this.http.post(`${config.baseUrl}/newUsersRecords`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    tradingRecordsServerSideAccordingToVessel(req) {
        return this.http.post(`${config.baseUrl}/tradingRecordsServerSideAccordingToVessel`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    tradingMessagesDataRecordsServerSide(req) {
        return this.http.post(`${config.baseUrl}/tradingMessagesDataRecordsServerSide`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    drawMessageInsert(req) {
        return this.http.post(`${config.baseUrl}/drawMessageInsert`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    drawNotificationInsert(req) {
        return this.http.post(`${config.baseUrl}/drawNotificationInsert`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    invitationEmailSend(req) {
        return this.http.post(`${config.baseUrl}/invitationEmailSend`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    invitedUserRecordsServerSide(req) {
        return this.http.post(`${config.baseUrl}/invitedUserRecordsServerSide`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    invitationEmailSendCompanyAdmin(req) {
        return this.http.post(`${config.baseUrl}/invitationEmailSendCompanyAdmin`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    /**Draw Change ownership */
    drawchangeOwnerShip(req) {
        return this.http.post(`${config.baseUrl}/drawchangeOwnerShip`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    /** Share Trade */
    shareDraw(req) {
        return this.http.post(`${config.baseUrl}/shareDraw`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }
    
    updateshareDraw(req) {
        return this.http.post(`${config.baseUrl}/updateshareDraw`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    changeOwnerShip(req) {
        return this.http.post(`${config.baseUrl}/changeOwnerShip`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    emailSendCommon(req) {
        return this.http.post(`${config.baseUrl}/emailSendCommon`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    ownerShipRecordsServerSide(req) {
        return this.http.post(`${config.baseUrl}/ownerShipRecordsServerSide`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    shareTrade(req) {
        return this.http.post(`${config.baseUrl}/shareTrade`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    updateshareTrade(req) {
        return this.http.post(`${config.baseUrl}/updateshareTrade`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    shareTradeRecordsServerSide(req) {
        return this.http.post(`${config.baseUrl}/shareTradeRecordsServerSide`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    saveBid(req) {
        return this.http.post(`${config.baseUrl}/saveBid`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }


    saveBidRecords(req) {
        return this.http.post(`${config.baseUrl}/saveBidRecords`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    TradePdfcreate(req) {
        return this.http.post(`${config.baseUrl}/TradePdfcreate`,
            req, { headers: this.headers })
    }

    TradePdflist(req) {
        return this.http.post(`${config.baseUrl}/TradePdflist`,
            req, { headers: this.headers });
    }

    tradePdfDelete(req) {
        return this.http.post(`${config.baseUrl}/tradePdfDelete`, req)
    }

    drawPdfDelete(req) {
        return this.http.post(`${config.baseUrl}/drawPdfDelete`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    createAddendum(req) {
        return this.http.post(`${config.baseUrl}/Addendumcreate`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    UpdateAddendum(req) {
        return this.http.post(`${config.baseUrl}/Addendumupdate`, req)
    }

    getAddendums(req) {
        return this.http.post(`${config.baseUrl}/Addendumlist`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }


    //   draw

    drawPdfcreate(req) {
        return this.http.post(`${config.baseUrl}/drawPdfcreate`,
            req,
            { headers: this.headers })
    }

    drawPdflist(req) {
        return this.http.post(`${config.baseUrl}/drawPdflist`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers })
    }

    drawAddendumcreate(req) {
        return this.http.post(`${config.baseUrl}/drawAddendumcreate`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    drawUpdateAddendum(req) {
        return this.http.post(`${config.baseUrl}/drawAddendumupdate`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    drawgetAddendums(req) {
        return this.http.post(`${config.baseUrl}/drawAddendumlist`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    // Trade Addendum ====================

    TradeAddendumcreate(req) {
        return this.http.post(`${config.baseUrl}/TradeAddendumcreate`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    tradeAddendumupdate(req) {
        return this.http.post(`${config.baseUrl}/tradeAddendumupdate`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    TradeAddendumlist(req) {
        return this.http.post(`${config.baseUrl}/TradeAddendumlist`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }


    riderClausescreate(req) {
        return this.http.post(`${config.baseUrl}/riderClausescreate`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    traderiderClausescreate(req) {
        return this.http.post(`${config.baseUrl}/traderiderClausescreate`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    textsFromWordFile(req) {
        return this.http.post(`${config.baseUrl}/textsFromWordFile`, req)
    }



    getTextsFromUploadedURL(req) {
        return this.http.post(`${config.baseUrl}/getTextsFromUploadedURL`,
            req, { headers: this.headers })
    }

    traderiderClauseslist(req) {
        return this.http.post(`${config.baseUrl}/traderiderClauseslist`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    riderClauseslist(req) {
        return this.http.post(`${config.baseUrl}/riderClauseslist`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }


    getCalligraphy() {
        return this.http.get(`${config.baseUrl}/getCalligraphy`, { headers: this.headers });
    }

    saveCalligraphyForuser(req) {
        return this.http.post(`${config.baseUrl}/saveCalligraphyForuser`, req)
    }

    alertCategoriesList(req) {
        return this.http.post(`${config.baseUrl}/alertCategoriesList`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    userSystemAlertCategoryUpdate(req) {
        return this.http.post(`${config.baseUrl}/userSystemAlertCategoryUpdate`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    clauseCategoryAndClauseTermsRecordsAccordingToCpForm(req) {
        return this.http.post(`${config.baseUrl}/clauseCategoryAndClauseTermsRecordsAccordingToCpForm`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }


    claueseCategorylistCustom(req) {
        return this.http.post(`${config.baseUrl}/claueseCategorylistCustom`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers })
    }

    clauseCategoryTermsRecordsCustom(req) {
        return this.http.post(`${config.baseUrl}/clauseCategoryTermsRecordsCustom`, req)
    }

    clauseCategoryRecordsAccordingToCpForm(req) {
        return this.http.post(`${config.baseUrl}/clauseCategoryRecordsAccordingToCpForm`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers })
    }

    chatBoardUsersCreate(req) {
        return this.http.post(`${config.baseUrl}/chatBoardUsersCreate`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    chatMessageCreate(req) {
        return this.http.post(`${config.baseUrl}/chatMessageCreate`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    unreadMessages(req) {
        return this.http.post(`${config.baseUrl}/unreadMessages`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    updateLastMessage(req) {
        return this.http.post(`${config.baseUrl}/updateLastMessage`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    getUnreadCounts(req) {
        return this.http.post(`${config.baseUrl}/getUnreadCounts`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    fetchChatDetailsInfo(req) {
        return this.http.post(`${config.baseUrl}/fetchChatDetailsInfo`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    userroleread(req) {
        return this.http.post(`${config.baseUrl}/userroleread`,
        { info: this.cryptoJS.encryptData(req) },
        { headers: this.headers });
    }


    BrokerManegeAlerlist() {
        return this.http.get(`${config.baseUrl}/BrokerManegeAlerlist`,
            { headers: this.headers });
    }

    drawOtpSend(req) {
        return this.http.post(`${config.baseUrl}/drawOtpSend`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    drawVerifyOtp(req) {
        return this.http.post(`${config.baseUrl}/drawVerifyOtp`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    tradeOtpSend(req) {
        return this.http.post(`${config.baseUrl}/tradeOtpSend`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    /**New Changes for custom category */
    customclaueseCategorycreate(req) {
        return this.http.post(`${config.baseUrl}/customclaueseCategorycreate`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    customclaueseDetailcreate(req) {
        return this.http.post(`${config.baseUrl}/customclaueseDetailcreate`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    updateclaueseCategorycreate(req) {
        return this.http.post(`${config.baseUrl}/updateclaueseCategorycreate`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    drawcustomclaueseCategorycreate(req) {
        return this.http.post(`${config.baseUrl}/drawcustomclaueseCategorycreate`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    drawcustomclaueseDetailcreate(req) {
        return this.http.post(`${config.baseUrl}/drawcustomclaueseDetailcreate`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    getdrawcustomclaueseCategorycreate(req) {
        return this.http.post(`${config.baseUrl}/getdrawcustomclaueseCategorycreate`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    tradeVerifyOtp(req) {
        return this.http.post(`${config.baseUrl}/tradeVerifyOtp`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    StandardBidFormDataUpdate(req) {
        return this.http.post(`${config.baseUrl}/StandardBidFormDataUpdate`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    removeUser(req) {
        return this.http.post(`${config.baseUrl}/removeUser`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    createUser(req) {
        return this.http.post(`${config.baseUrl}/createUser`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    /* broker managment */

    getBrokerCompanyAssignments(req) {
        return this.http.post(`${config.baseUrl}/getBrokerCompanyAssignments`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    updateBrokerCompanyAssignment(req) {
        return this.http.post(`${config.baseUrl}/updateBrokerCompanyAssignment`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers });
    }

    requestToAddCompanyASBA(req) {
        return this.http.post(`${config.baseUrl}/requestToAddCompanyASBA`, req, { headers: this.headers });
    }

    companyprofileupdate(req) {
        return this.http.post(`${config.baseUrl}/companyprofileupdate`, req, { headers: this.headers });
    }

    getcompanyprofile(req) {
        return this.http.post(`${config.baseUrl}/getcompanyprofile`, req, { headers: this.headers });
    }

    /*Ports managment */
    getPortsList() {
        return this.http.get(`${config.baseUrl}/getPortsList`,
            { headers: this.headers });
    }

    userreportchangelist(req) {
        return this.http.post(`${config.baseUrl}/userreportchangelist`, { info: this.cryptoJS.encryptData(req) }, { headers: this.headers });
    }

    reportchanges(req) {
        return this.http.post(`${config.baseUrl}/reportchanges`, req, { headers: this.headers });
    }

    verifyDocumentDetails(req) {
        return this.http.post(`${config.baseUrl}/verifyDocumentDetails`, { info: this.cryptoJS.encryptData(req) }, { headers: this.headers });
    }

    updateProgress(req) {
        return this.http.post(`${config.baseUrl}/updateProgress`, { info: this.cryptoJS.encryptData(req) }, { headers: this.headers });
    }

    pushNotificationTokenUpdate(req) {
        return this.http.post(`${config.baseUrl}/pushNotificationTokenUpdate`, { info: this.cryptoJS.encryptData(req) }, { headers: this.headers });
    }

    getCountries(req) {
        return this.http.post(`${config.baseUrl}/getCountry`,
            { info: this.cryptoJS.encryptData(req) }, { headers: this.headers })
    }

    getCitytimezone(req) {
        return this.http.post(`${config.baseUrl}/citytimezone`,
            { info: this.cryptoJS.encryptData(req) }, { headers: this.headers })
    }
    getassignbrokercompany(req) {
        return this.http.post(`${config.baseUrl}/getassignbrokercompany`,
            { info: this.cryptoJS.encryptData(req) }, { headers: this.headers })
    }

    getStates(req) {
        return this.http.post(`${config.baseUrl}/getState`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers })
    }
    getCities(req) {
        return this.http.post(`${config.baseUrl}/getCity`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers })
    }

    clauseCategoryuncheckedRecordsServerSideTrading(req) {
        return this.http.post(`${config.baseUrl}/clauseCategoryuncheckedRecordsServerSideTrading`,
            { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers })
    }

    getIpadress() {
        // return this.http.get('https://ifconfig.co/ip');
        return this.http.get('https://jsonip.com');
    }


    useractivity(req){
        return this.http.post(`${config.baseUrl}/useractivity`,
        { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers })
    }

    /** Draw CP mail & notification */
    drawmailnotification(req){
        return this.http.post(`${config.baseUrl}/drawmailnotification`,
        { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers })
    }

    drawpushnotification(req){
        return this.http.post(`${config.baseUrl}/drawpushnotification`,
        { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers })
    }

    /** Trade mail & notification */
    trademailnotification(req){
        return this.http.post(`${config.baseUrl}/trademailnotification`,
        { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers })
    }

    tradepushnotification(req){
        return this.http.post(`${config.baseUrl}/tradepushnotification`,
        { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers })
    }

    completeTradeNotification(req){
        return this.http.post(`${config.baseUrl}/completeTradeNotification`,
        { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers })
    }

    tradeDataRemove(req){
        return this.http.post(`${config.baseUrl}/tradeDataRemove`,
        { info: this.cryptoJS.encryptData(req) },
            { headers: this.headers })
    }
}